export const klaviyoTrackEvents = {
  VIEWED_PRODUCT: "viewed-product",
  STARTED_CHECKOUT: "started-checkout",
} as const;

export const klaviyoTrackEventEligibilityKeys = {
  [klaviyoTrackEvents.VIEWED_PRODUCT]: "trackViewedProduct",
  [klaviyoTrackEvents.STARTED_CHECKOUT]: "trackStartedCheckout",
} as const;

export const klaviyoTrackEventLabels = {
  [klaviyoTrackEvents.VIEWED_PRODUCT]: "Viewed Product",
  [klaviyoTrackEvents.STARTED_CHECKOUT]: "Started Checkout",
} as const;
