import IconArrow from "@/components/atoms/Icon/icons/Arrow/index.vue";
import IconChat from "@/components/atoms/Icon/icons/Chat/index.vue";
import IconCheckmark from "@/components/atoms/Icon/icons/Checkmark/index.vue";
import IconChevron from "@/components/atoms/Icon/icons/Chevron/index.vue";
import IconClose from "@/components/atoms/Icon/icons/Close/index.vue";
import IconCustomize from "@/components/atoms/Icon/icons/Customize/index.vue";
import IconDelivery from "@/components/atoms/Icon/icons/Delivery/index.vue";
import IconFilter from "@/components/atoms/Icon/icons/Filter/index.vue";
import IconHeart from "@/components/atoms/Icon/icons/Heart/index.vue";
import IconLanguage from "@/components/atoms/Icon/icons/Language/index.vue";
import IconMenu from "@/components/atoms/Icon/icons/Menu/index.vue";
import IconMinus from "@/components/atoms/Icon/icons/Minus/index.vue";
import IconPackage from "@/components/atoms/Icon/icons/Package/index.vue";
import IconPhone from "@/components/atoms/Icon/icons/Phone/index.vue";
import IconPlus from "@/components/atoms/Icon/icons/Plus/index.vue";
import IconSearch from "@/components/atoms/Icon/icons/Search/index.vue";
import IconShoppingBag from "@/components/atoms/Icon/icons/ShoppingBag/index.vue";
import IconFacebook from "@/components/atoms/Icon/icons/socials/Facebook/index.vue";
import IconInstagram from "@/components/atoms/Icon/icons/socials/Instagram/index.vue";
import IconLinkedIn from "@/components/atoms/Icon/icons/socials/LinkedIn/index.vue";
import IconSort from "@/components/atoms/Icon/icons/Sort/index.vue";
import IconUser from "@/components/atoms/Icon/icons/User/index.vue";
import IconZoom from "@/components/atoms/Icon/icons/Zoom/index.vue";
import IconPlay from "@/components/atoms/Icon/icons/Play/index.vue";
import IconDocument from "@/components/atoms/Icon/icons/Document/index.vue";
import IconMaxMin from "@/components/atoms/Icon/icons/MaxMin/index.vue";
import IconEmail from "@/components/atoms/Icon/icons/Email/index.vue";

export {
  IconArrow,
  IconChat,
  IconCheckmark,
  IconChevron,
  IconClose,
  IconCustomize,
  IconDelivery,
  IconFilter,
  IconHeart,
  IconLanguage,
  IconMenu,
  IconMinus,
  IconPackage,
  IconPhone,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconFacebook,
  IconInstagram,
  IconSort,
  IconUser,
  IconZoom,
  IconPlay,
  IconDocument,
  IconMaxMin,
  IconEmail,
  IconLinkedIn,
};
