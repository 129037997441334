import { cookieBotEvents } from "~/constants/cookieBotEvents";

export default defineNuxtPlugin(() => {
  const { sessionContext } = useSessionContext();
  const { getKlaviyoPageDetails } = useKlaviyo();
  watch(
    sessionContext,
    (session, prevSession) => {
      if (!session?.token || session?.token === prevSession?.token) return;
      getKlaviyoPageDetails();
    },
    {
      immediate: true,
    },
  );

  window.addEventListener(cookieBotEvents.ON_ACCEPT, () => {
    getKlaviyoPageDetails();
  });
});
