export const useTrustedShops = createSharedComposable(() => {
  const { getTrustedShopsConfig } = useStoreApi();
  const { data: trustedShopsConfig } = useAsyncData(() => {
    return getTrustedShopsConfig();
  });

  const trustedShopsAttributes = computed(() => {
    const domains = Object.values(trustedShopsConfig?.value?.[0]?.data || {});
    if (domains.length !== 1) return {};
    const domainSettings = domains?.[0]?.["de-DE"];
    if (!domainSettings) return {};

    const scriptConfiguration =
      domainSettings?.trustbadgeConfiguration?.children?.[0]?.attributes;
    if (!scriptConfiguration) return {};
    const attributesObj = Object.values(scriptConfiguration).reduce(
      (acc: Record<string, PropertyKey>, objAttribute) => {
        return {
          ...acc,
          [objAttribute?.attributeName]: objAttribute?.value ?? true,
        };
      },
      {},
    );

    return attributesObj;
  });

  return {
    trustedShopsAttributes,
  };
});
