const falseErrorKeys = ["promotion-discount-added"] as const;
const infoKeys = [
  "promotions-on-cart-price-zero-error",
  "promotion-excluded",
  "promotion-not-eligible",
] as const;

export const reservedPositiveCartErrors = {
  falseErrorKeys,
  infoKeys,
} as const;
