import { defineStore } from "pinia";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";
import { v4 as uuidv4 } from "uuid";

export const useGratisProductsDetailStore = defineStore(
  "gratis-products",
  () => {
    if (!isInNuxtInstance())
      return {
        product: null,
        addProduct: () => {},
        pending: true,
        remainProductsQuantity: {},
      };

    const { addProductWithOptions, refreshCart } = useCart();
    const {
      pending: gratisProductsPending,
      data: gratisProducts,
      execute: refetchGratisProducts,
    } = useAsyncData("gratis-products", () => {
      return shopwareExtensionsApi.methods.crehler.gratisProducts.getGratisProducts();
    });

    const firstAvailableProduct = computed(() => {
      const promotionWithAvailableQuantity = gratisProducts.value?.find(
        (product) => product.quantity > 0,
      );
      return promotionWithAvailableQuantity?.availableProducts?.find(
        (product) => product.available,
      );
    });

    const handleAddProduct = async () => {
      if (!firstAvailableProduct.value?.id) return;

      const randomId = uuidv4();

      const options = {
        stackable: false,
        removable: true,
        referencedId: firstAvailableProduct.value.id,
      };

      await addProductWithOptions({
        id: randomId.replace(/-/g, ""),
        quantity: 1,
        options,
      });
      await refetchGratisProducts();
      await refreshCart();
    };

    const remainProductsQuantity = computed(() => {
      return gratisProducts.value?.reduce((acc, promotion) => {
        const promotionProductsQuantityLeft =
          promotion.availableProducts.reduce((promotionAcc, product) => {
            promotionAcc[product.id] = promotion.quantity;

            return promotionAcc;
          }, {});

        return { ...acc, ...promotionProductsQuantityLeft };
      }, {});
    });

    return {
      product: firstAvailableProduct,
      addProduct: handleAddProduct,
      refetchGratisProducts,
      pending: gratisProductsPending,
      remainProductsQuantity,
    };
  },
);
