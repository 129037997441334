export const storeApiEndpoints = {
  media: "/media-webp",
  navigation: "/navigation",
  seoUrl: "/seo-url",
  systemConfigBasicInformation: "/system-config?domain=core.basicInformation",
  sawadeEngineConfig: "/system-config?domain=CrehlerSawadeEngine.config",
  trustedShopsConfig: "/system-config?domain=trustedShopsApp.config",
  paypalConfig: "/system-config?domain=SwagPayPal.settings",
  category: "/category",
  product: "/product",
  findRedirect: "/find-redirect",
  createPaypalOrderByOrderId: "/paypal/create-order",
  handlePayment: "/handle-payment",
  klaviyoPage: `/cr/klaviyo/page`,
  klaviyoViewProductEvent: "/cr/klaviyo/product",
  klaviyoCheckoutStartEvent: "/cr/klaviyo/checkout",
} as const;
