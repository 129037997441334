import { loadScript } from "@paypal/paypal-js";

export const usePayPal = createSharedComposable(() => {
  const paypal = ref<Awaited<ReturnType<typeof loadScript>> | void>();

  const setup = async () => {
    const { getPayPalConfig } = useStoreApi();

    const data = await getPayPalConfig();
    const settings = data?.[0]?.data;
    if (!settings) {
      console.error("PayPal settings not found");
      return;
    }

    const isSandbox = settings?.sandbox;
    const clientId = isSandbox ? settings?.clientIdSandbox : settings?.clientId;
    const environment = isSandbox ? "sandbox" : "production";
    const newPayPalInstance = await loadScript({
      components: ["card-fields", "buttons"],
      currency: "EUR",
      enableFunding: "card",
      environment: environment,
      clientId: clientId,
    }).catch((err) => {
      console.error("failed to load the PayPal JS SDK script", err);
    });
    paypal.value = newPayPalInstance;
  };

  return {
    setup,
    paypal,
  };
});
