export const listingTypes = {
  CATEGORY_LISTING: "categoryListing",
  PRODUCT_SEARCH_LISTING: "productSearchListing",
} as const;

export const listingRenderTypes = {
  HIDE_CMS_BLOCKS: "hideCmsBlocks",
  DISPLAY_CMS_BLOCKS: "displayCmsBlocks",
} as const;

export const listingBlockNamePartsSpecialFlags = {
  SAVE: "(save)",
  PUT_LISTING_BEFORE: "(put-listing-before)",
} as const;

export const listingDefaultSortingOrder = "position";

export const LISTING_PRODUCT_LIMIT = 24;
