import type { Schemas, operations } from "#shopware";
import { useCartFunction as swUseCart } from "@shopware-pwa/composables-next";
import { reservedPositiveCartErrors } from "@/constants/reservedPositiveCartErrors";

type LineItemProductParams = {
  id: string;
  quantity: number;
  type: "product";
};

const _useCart = (): UseCartReturn & {
  addProductWithOptions: typeof addProductWithOptions;
  addProducts: typeof addProducts;
  cartFeedback: typeof cartFeedback;
} => {
  const { apiClient } = useShopwareContext();
  const useCartData: UseCartReturn = swUseCart();
  const { t } = useI18n();

  const _storeCart = useContext<Schemas["Cart"]>("swCart");
  const _storeCartErrors = useContext<Schemas["Cart"]["errors"] | null>(
    "swCartErrors",
  );

  /**
   * Example on how to override the default `refreshCart` method
   *
   * @param {Schemas["Cart"]} newCart
   * @returns
   */
  async function refreshCart(
    newCart?: Schemas["Cart"],
  ): Promise<Schemas["Cart"]> {
    return useCartData.refreshCart(newCart);
  }

  const setCartErrors = (cart: Schemas["Cart"]) => {
    if (Object.keys(cart.errors || {}).length) {
      _storeCartErrors.value = Object.assign(
        _storeCartErrors.value ? _storeCartErrors.value : {},
        cart.errors,
      );
    }
  };

  async function addProductWithOptions(params: {
    id: string;
    quantity?: number;
    options?: Record<string, string | number | boolean>;
  }): Promise<Schemas["Cart"]> {
    const safeOptions = params.options || {};
    const addToCartResult = await apiClient.invoke(
      "addLineItem post /checkout/cart/line-item",
      {
        items: [
          {
            id: params.id,
            referencedId: params.id,
            quantity: params.quantity,
            type: "product",
            ...safeOptions,
          },
        ],
      },
    );
    _storeCart.value = addToCartResult;
    setCartErrors(addToCartResult);
    return addToCartResult;
  }

  async function addProducts(
    items: LineItemProductParams[],
  ): Promise<Schemas["Cart"]> {
    if (items.length <= 0) {
      return _storeCart.value;
    }

    if (items.length === 1) {
      return addProductWithOptions({
        id: items[0].id,
        quantity: items[0].quantity,
      });
    }
    const { data: addToCartResult } = await apiClient.invoke(
      "addLineItem post /checkout/cart/line-item",
      {
        items,
      },
    );

    return addToCartResult;
  }

  const cartFeedback = computed(() => {
    const falseErrorKeys = reservedPositiveCartErrors.falseErrorKeys;
    const infoKeys = reservedPositiveCartErrors.infoKeys;
    const errors = useCartData.cart.value?.errors;
    if (!errors)
      return {
        negative: [],
        positive: [],
      };

    const errorsDict = {};
    for (const key in errors) {
      const error = errors[key];
      const translatedSnippetError = t(
        `checkout.${error?.messageKey}`,
        error?.parameters || {},
      );
      if (errorsDict[translatedSnippetError] === undefined) {
        errorsDict[translatedSnippetError] = error;
      }
    }

    const negativeFeedbacks = Object.entries(errorsDict)
      .filter(([_, error]) => {
        return (
          !falseErrorKeys.includes(error?.messageKey) &&
          !infoKeys.includes(error?.messageKey)
        );
      })
      .map(([translatedError, error]) => ({
        ...error,
        translated: translatedError,
      }));

    const positiveFeedbacks = Object.entries(errorsDict)
      .filter(([_, error]) => {
        return falseErrorKeys.includes(error?.messageKey);
      })
      .map(([translatedError, error]) => ({
        ...error,
        translated: translatedError,
      }));

    const infoFeedbacks = Object.entries(errorsDict)
      .filter(([_, error]) => {
        return infoKeys.includes(error?.messageKey);
      })
      .map(([translatedError, error]) => ({
        ...error,
        translated: translatedError,
      }));

    return {
      negative: negativeFeedbacks,
      positive: positiveFeedbacks,
      info: infoFeedbacks,
    };
  });

  return {
    ...useCartData,
    refreshCart,
    addProductWithOptions,
    addProducts,
    cartFeedback,
  };
};

export const useCart = createSharedComposable(_useCart);
