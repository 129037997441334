import { reactive, computed } from "vue";
import { defineStore } from "pinia";
import { useStoreApi } from "@/composables/useStoreApi";
import { getCategoryRoute } from "@shopware-pwa/helpers-next";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";

type CategoryRoute = ReturnType<typeof getCategoryRoute>;

export const useSystemConfigBasicInformationStore = defineStore(
  "system-config-basic-information",
  () => {
    if (!isInNuxtInstance()) {
      return {
        loadShopPages: () => {},
        getShopPageRoute: () => null,
        computedShopPageRoutes: computed(() => ({})),
        computedShopPageHrefs: computed(() => ({})),
      };
    }

    const { getSystemConfigBasicInformation, getCategoriesByCmsPageIds } =
      useStoreApi();

    const shopPageRoutes = reactive<{
      [key: string]: CategoryRoute | null;
    }>({
      tosPage: null,
      revocationPage: null,
      shippingPaymentInfoPage: null,
      privacyPage: null,
      imprintPage: null,
      contactPage: null,
      newsletterPage: null,
    });

    const loadShopPages = async () => {
      const data = await getSystemConfigBasicInformation();
      let emailInformationReference = null;
      data?.[0]?.data?.elements?.forEach((informationPart) => {
        if (informationPart.name === "core.basicInformation.email") {
          emailInformationReference = informationPart;
        }
        if (informationPart.name === "core.basicInformation.shopName") {
          basicInformation.shopName = informationPart?.value || "";
        }
      });

      basicInformation["email"] = emailInformationReference?.value || "";
      const shopPagesElements = data?.[0]?.data?.elements?.filter(
        (informationPart) => {
          return informationPart?.name?.endsWith("Page");
        },
      );
      if (
        !Array.isArray(shopPagesElements) ||
        shopPagesElements?.length === 0
      ) {
        return;
      }

      const detailedShopPageIds = shopPagesElements
        .map((informationElement) => {
          const pagePrefix = "core.basicInformation.";
          const pageNameWithoutPrefix = informationElement?.name?.replace(
            pagePrefix,
            "",
          ) as keyof typeof shopPageRoutes;

          if (shopPageRoutes[pageNameWithoutPrefix] === undefined) {
            return null;
          }

          return {
            pageName: pageNameWithoutPrefix,
            pageId: informationElement.value,
          };
        })
        .filter(Boolean) as { pageName: string; pageId: string }[];

      const shopPageIds = detailedShopPageIds.map((page) => page?.pageId);
      if (!Array.isArray(shopPageIds) || shopPageIds?.length === 0) {
        return;
      }
      const resolvedCategories = await getCategoriesByCmsPageIds(shopPageIds);
      if (
        !Array.isArray(resolvedCategories?.elements) ||
        resolvedCategories?.elements.length === 0
      ) {
        return;
      }

      detailedShopPageIds.forEach((page) => {
        const category = resolvedCategories?.elements.find((category) => {
          return category.cmsPageId === page?.pageId;
        });

        if (!category) {
          return;
        }

        const route = getCategoryRoute(category);
        const pageName = page.pageName as keyof typeof shopPageRoutes;
        if (!pageName) return;
        shopPageRoutes[pageName] = route;
      });
    };

    const computedShopPageRoutes = computed(() => {
      return shopPageRoutes;
    });

    const computedShopPageHrefs = computed(() => {
      return Object.entries(shopPageRoutes).reduce(
        (acc, [key, value]) => {
          if (typeof value === "string") {
            acc[key] = value;
            return acc;
          }

          if (typeof value === "object" && value !== null) {
            acc[key] = value.path;
            return acc;
          }
          return acc;
        },
        {} as Record<keyof typeof shopPageRoutes, string | null>,
      );
    });

    const getShopPageRoute = (pageName: keyof typeof shopPageRoutes) => {
      return shopPageRoutes[pageName];
    };

    const basicInformation = reactive<{
      email: string;
      shopName: string;
    }>({
      email: "",
      shopName: "",
    });

    return {
      loadShopPages,
      getShopPageRoute,
      computedShopPageRoutes,
      computedShopPageHrefs,
      basicInformation,
    };
  },
);
