import type { KlaviyoPageConfig, KlaviyoViewProductEvent } from "~/types";
import {
  klaviyoTrackEvents,
  klaviyoTrackEventEligibilityKeys,
  klaviyoTrackEventLabels,
} from "@/constants/klaviyo";

import { VIEW_PRODUCT_EVENT_DTO } from "@/dto/klaviyo/viewProductEvent";

export const useKlaviyo = createSharedComposable(() => {
  const { klaviyo } = useStoreApi();
  const klaviyoConfig = ref<null | KlaviyoPageConfig["0"]["data"]>(null);
  const klaviyoViewProductDetails = ref<
    null | KlaviyoViewProductEvent["0"]["data"]
  >(null);

  const getKlaviyoPageDetails = async () => {
    if (!hasUserConsent()) return Promise.resolve(null);
    return klaviyo
      .getPage()
      .then((response) => {
        klaviyoConfig.value = response?.[0]?.data ?? null;
      })
      .catch((e) => {
        console.error("Failed to fetch Klaviyo page config", e);
      });
  };

  const hasUserConsent = () => {
    return window?.Cookiebot?.consent?.preferences;
  };

  const isEligibleToTrack = (
    trackEventType: (typeof klaviyoTrackEvents)[keyof typeof klaviyoTrackEvents],
  ) => {
    if (!hasUserConsent()) return false;

    if (!klaviyoConfig.value) {
      return false;
    }

    if (!klaviyoTrackEventEligibilityKeys[trackEventType]) {
      console.error(
        `No eligibility key found for event type: ${trackEventType}`,
      );
      return false;
    }

    return klaviyoConfig.value.configuration[
      klaviyoTrackEventEligibilityKeys[trackEventType]
    ];
  };

  // TODO: When we will have more events to track, we can create a generic function
  // to track events and pass the event type as an argument
  // e.g. triggerKlaviyoEvent(eventType: klaviyoTrackEvents.VIEWED_PRODUCT, params: {productId: string})
  const triggerKlaviyoViewProductEvent = async (productId: string) => {
    if (
      !isEligibleToTrack(klaviyoTrackEvents.VIEWED_PRODUCT) ||
      !klaviyoConfig.value?.customerIdentity
    )
      return Promise.resolve(null);
    klaviyo
      .triggerViewProductEvent(productId)
      .then((response) => {
        klaviyoViewProductDetails.value = response?.[0]?.data ?? null;
        if (
          !klaviyoViewProductDetails.value?.productInfo ||
          !klaviyoConfig.value?.customerIdentity
        )
          return;

        const productInfo = klaviyoViewProductDetails.value.productInfo;
        const propertiesPayload = {
          Categories: productInfo.Categories,
          ImageUrl: productInfo.ImageURL,
          ItemId: productInfo.SKU,
          Metadata: JSON.stringify({
            Brand: productInfo.Brand,
            Price: productInfo.Price,
            PriceBrutto: "",
          }),
          Title: productInfo.ProductName,
          Url: productInfo.URL,
        };
        const profilePayload = {
          first_name: klaviyoConfig.value?.customerIdentity?.firstName,
          email: klaviyoConfig.value?.customerIdentity?.email,
          last_name: klaviyoConfig.value?.customerIdentity?.lastName,
        };
        $fetch("/nuxtApi/klaviyo-track-event", {
          method: "POST",
          body: VIEW_PRODUCT_EVENT_DTO(
            propertiesPayload,
            klaviyoTrackEventLabels[klaviyoTrackEvents.VIEWED_PRODUCT],
            profilePayload,
          ),
        });
      })
      .catch((e) => {
        console.error("Failed to fetch Klaviyo product config", e);
      });
  };

  const triggerKlaviyoCheckoutStartEvent = async () => {
    if (!isEligibleToTrack(klaviyoTrackEvents.STARTED_CHECKOUT))
      return Promise.resolve(null);
    klaviyo.triggerCheckoutStartEvent().catch((e) => {
      console.error("Failed to fetch Klaviyo checkout start event", e);
    });
  };

  return {
    getKlaviyoPageDetails,
    triggerKlaviyoViewProductEvent,
    triggerKlaviyoCheckoutStartEvent,
    hasUserConsent,
    isEligibleToTrack,
    klaviyoConfig,
    klaviyoViewProductDetails,
  };
});
