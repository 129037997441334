export const VIEW_PRODUCT_EVENT_DTO = (
  properties: Record<PropertyKey, unknown>,
  eventName: string,
  userDetails: Record<"first_name" | "email" | "last_name", string>,
) => ({
  data: {
    type: "event",
    attributes: {
      properties: properties,
      metric: {
        data: {
          type: "metric",
          attributes: {
            name: eventName,
          },
        },
      },
      profile: {
        data: {
          type: "profile",
          attributes: userDetails,
        },
      },
    },
  },
});
